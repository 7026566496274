<template>
    <router-view></router-view>
    <van-tabbar v-show="!$route.meta.hideFooter" v-model="active"  active-color="#ee0a24"
        inactive-color="#000">
        <van-tabbar-item icon="wap-home" active-icon="home" to="/">
            首页
        </van-tabbar-item>
        <van-tabbar-item icon="chat" active-icon="chat" :badge="messageCount" @click="checkLogin('/my/message')">
            消息
        </van-tabbar-item>
        <van-tabbar-item icon="diamond" replace to="/integral">
            积分
        </van-tabbar-item>
        <van-tabbar-item icon="service" active-icon="service" @click="checkLogin('/customerService')">
            客服
        </van-tabbar-item>
        <van-tabbar-item icon="user" active-icon="user" @click="checkLogin('/my')">
            我的
        </van-tabbar-item>
    </van-tabbar>
</template>

<script setup>
import { computed, onMounted, onBeforeUpdate, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getMessageCount } from '@/api/authed/mine'
const route = useRoute()
const router = useRouter()
// 判断当前路由
const isHome = computed(() => route.path === '/')
const isMessage = computed(() => route.path === '/my/message')
const isTaxRefund = computed(() => route.path === '/integral')
// const isService = computed(() => route.path === '/service')
const isMine = computed(() => route.path === '/my')
const messageCount = ref(0)
const active = ref(0);
const checkLogin = (url) => {
    if (!localStorage.getItem('token')) {
        router.push('/login')
    }
    const expiryTime = localStorage.getItem('expiryTime')
    if (!expiryTime || new Date().getTime() > expiryTime) {
        router.push('/login')
    } else {
      router.push(url);
    }
}

onBeforeUpdate(() => {
    if (isMine.value) {
        active.value = 4
    } else if (isMessage.value) {
        active.value = 1
    } else if (isHome.value) {
        active.value = 0
    } else if (isTaxRefund.value) {
        active.value = 2
    } else {
        active.value = -1
    }
})
onMounted(() => {

    if (localStorage.getItem('token')) {
        const expiryTime = localStorage.getItem('expiryTime')
        if (expiryTime && new Date().getTime() < expiryTime) {
            // 获取消息数量
            getMessageCount().then(res => {
                messageCount.value = res.data
            }).catch(err => {

            })
            const interval = setInterval(() => {
                if (localStorage.getItem('token')) {
                    const expiryTime = localStorage.getItem('expiryTime')
                    if (expiryTime && new Date().getTime() < expiryTime) {
                        getMessageCount().then(res => {
                            messageCount.value = res.data
                        }).catch(err => {
                            clearInterval(interval)
                        })
                    }
                }
            }, 5000)
        }
    }
})



</script>

<style>
@import "style.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin: 0 auto;

  /* margin-top: 60px; */
}

.n-tabs {
    position: fixed;
    top: 41px;
    left: 0;
    width: 100%;
    height: 41px;
    overflow: hidden;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 0 10PX 0PX rgba(155, 143, 143, 0.6);
    -webkit-box-shadow: 0 0 10PX 0PX rgba(155, 143, 143, 0.6);
    -moz-box-shadow: 0 0 10PX 0PX rgba(155, 143, 143, 0.6);
}

.n-tabs .edge {
    position: fixed;
    top: 0;
    height: 41px;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
}

.n-tabs .n-tabContainer {
    -webkit-overflow-scrolling: touch;
    position: relative;
    top: 0;
    left: 0;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 8px;
    height: 51px;
    font-size: 14px;
    color: #333;
    white-space: nowrap;
}

.n-tabs .n-tabContainer .navtab {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
}

.n-tabs .n-tabContainer .n-tabItem {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    display: inline-block;
    height: 41px;
    line-height: 41px;
    text-align: center;
    margin-left: 5px;
}

.n-tabs .n-tabContainer .n-tabItem:first-child {
    margin-left: 0;
}

.n-tabs .n-tabContainer .n-tabItem .current {
    display: inline-block;
    height: 41px;
    border-bottom: 2px solid #e31436;
    color: #e31436;
}

.t-bg {
    background-image: url('static/images/bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.aui-badge {
    display: inline-block;
    width: auto;
    text-align: center;
    vertical-align: top;
    min-width: 0.8rem;
    height: 0.8rem;
    line-height: 0.8rem;
    padding: 0 0.2rem;
    font-size: 0.6rem;
    color: #ffffff;
    background-color: #ff2600;
    border-radius: 0.4rem;
    position: static;
    margin-left: -10px;
    top: 0.2rem;
    left: 60%;
    z-index: 99;
}
</style>
