<template>
  <GoBackHeader title="绑定银行卡"/>

  <div class="mt-2">
    <van-cell-group inset>
      <van-field label="会员ID" :model-value=" userInfo.id" disabled/>
      <van-field label="账号" :model-value=" userInfo.account" disabled/>

      <van-field label="姓名"  v-model=" userInfo.realName" required/>
      <van-field v-model="userInfo.bank" required label="银行" placeholder="请输入银行名称"/>
      <van-field v-model="userInfo.bankCardNo" required label="卡号" placeholder="请输入卡号"/>
    </van-cell-group>
  </div>
  <div class="mx-10 mt-2">
    <button type="button" @click="submitChange" class="w-full h-12 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-base font-bold rounded-lg border border-transparent bg-blue-600 text-white  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
      修改资料
    </button>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import {modifyUserInfo} from '@/api/authed/settings'
import { getUserInfo } from '@/api/authed/mine';
import {showToast} from 'vant';
import GoBackHeader from "@/components/common/GoBackHeader.vue";

const userInfo = ref({})
const rex = /^[\u4e00-\u9fa5]{1,}$/

onMounted(() => {
  getUserInfo().then(res=>{
    if(res.code == 200){
      userInfo.value = res.data
    }else{
      userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
    }
  })

})

const submitChange = () => {
  if (userInfo.value.realName === '' || userInfo.value.realName === null || userInfo.value.realName === undefined) {
    showToast('姓名不能为空')
    return
  }

  if (!rex.test(userInfo.value.realName)) {
    showToast('姓名必须为中文')
    return
  }
  if (userInfo.value.bank == '' || userInfo.value.bank == null || userInfo.value.bank == undefined) {
    showToast('银行名称不能为空')
    return
  }

  if (userInfo.value.bankCardNo == '' || userInfo.value.bankCardNo == null || userInfo.value.bankCardNo == undefined) {
    showToast('卡号不能为空')
    return
  }

  const param = {
    realName: userInfo.value.realName,
    bank: userInfo.value.bank,
    bankCardNo: userInfo.value.bankCardNo,
    id: userInfo.value.id
  }
  modifyUserInfo(param).then(res => {
    if (res.code == 200) {
      showToast('修改成功')
      localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
    } else {
      showToast(res.msg)
    }
  }).catch(()=>{

  })
}
</script>
